@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

input:checked~.dot {
    transform: translateX(100%);
    background-color: #0EA5E9;
}

input.checkbox_module:checked+label {
    border: 1px solid #00a09d;
}

input.checkbox_module:checked+label>div.icon-check>div {
    border: 1px solid #23923d;
    background: #28a745;
    color: white;
    text-shadow: 0 1px 0 #23923d, 0 -1px 0 #23923d, 1px 0 0 #23923d, -1px 0 0 #23923d;
}

.ck-editor__editable {
    min-height: 200px;
}

.select2-container {
    width: 100% !important;
}

.ck-content {
    color: #212529;
}

.ck-content a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.ck-content ul {
    padding-left: 2rem;
    list-style-type: disc;
}

.ck-content ol {
    padding-left: 2rem;
    list-style-type: decimal;
}

.select2-selection__choice {
    background-color: #7e3af2 !important;
    border-color: #7e3af2 !important;
    color: #ffffff !important;
}

.select2-selection__choice .select2-selection__choice__remove {
    color: #ffffff !important;
}

#course_edit #videos:last-child {
    border-bottom: none !important;
}